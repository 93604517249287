export const LearningHubLocale = {
  en: {
    'course-name': 'Course Name',
    category: 'Category',
    'course-link': 'Course Link',
    description: 'Description',
    open_course: 'Open Course',
  },
  ar: {
    'course-name': 'اسم الدورة',
    category: 'الفئة',
    'course-link': 'رابط الدورة',
    description: 'الوصف',
    open_course: 'افتح الدورة',
  },
  ru: {
    'course-name': 'Название курса',
    category: 'Категория',
    'course-link': 'Ссылка на курс',
    description: 'Описание',
    open_course: 'Открыть курс',
  },
  cn: {
    'course-name': '课程名称',
    category: '类别',
    'course-link': '课程链接',
    description: '描述',
    open_course: '打开课程',
  },
};

export const LearningHubLocalePath = 'LearningHub';
