import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from "react";
import { useTranslation } from "react-i18next";
import { Spinner, Tables } from "../../../../../Components";
import {
  GetParams,
  showError,
  showSuccess,
  showWarn,
} from "../../../../../Helper";
import { TableActions } from "../../../../../Enums";
import { MessageConfirmDialog } from "../../../../../SharedComponents";
import {
  DeleteSocialMediaLogoIdAPI,
  GetSocialMediaLogoByIdAPI,
} from "../../../../../Services";
import { SocialMediaLinkComponent } from "./SocialMediaLinkComponent";

export const SocialMediaLinkComponentTable = ({
  translationPath,
  parentTranslationPath,
  data,
  filter,
  setFilter,
  reloadData,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [activeItem, setActiveItem] = useState(null);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isMissingInfoMSGOpen, setIsMissingInfoMSGOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({ info: false, delete: false });
  const [socialMediaLink, setSocialMediaLink] = useState(null);

  const DeleteHandler = useCallback(async () => {
    setIsLoading((item) => ({ ...item, delete: true }));

    const res = await DeleteSocialMediaLogoIdAPI(
      activeItem && activeItem.socialMediaLogoId
    );
    if (res && res.ErrorId) {
      showError(t(`${translationPath}failed-to-delete-social-media-logo`));
    } else if (!(res && res.status && res.status !== 200)) {
      setIsMissingInfoMSGOpen(false);
      showSuccess(
        t(`${translationPath}social-media-logo-deleted-sucessfuully`)
      );
      reloadData();
    } else showError(t(`${translationPath}failed-to-delete-social-media-logo`));
    setIsLoading((item) => ({ ...item, delete: false }));
  }, [activeItem]);

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveItem(item);
      if (actionEnum === TableActions.deleteText.key)
        setIsMissingInfoMSGOpen(true);
      else if (actionEnum === TableActions.editText.key)
        setIsOpenEditDialog(true);
    },
    []
  );

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const GetSocialMediaLinkLogo = useCallback(async () => {
    setIsLoading((item) => ({ ...item, info: true }));
    const res = await GetSocialMediaLogoByIdAPI(
      activeItem && activeItem.socialMediaLogoId
    );
    if (res && res.ErrorId) {
      showError(t(`${translationPath}failed-to-get-social-media-logo`));
    } else if (!(res && res.status && res.status !== 200)) {
      setSocialMediaLink(res);
    }
    setIsLoading((item) => ({ ...item, info: false }));
  }, [activeItem]);

  useEffect(() => {
    if (activeItem) {
      GetSocialMediaLinkLogo(activeItem.socialMediaLogoId);
    }
  }, [activeItem]);

  return (
    <div className="d-flex-column mt-2">
      <Spinner isActive={isLoading.info || isLoading.delete} isAbsolute />
      <Tables
        data={data ? data.result : []}
        headerData={[
          {
            id: 1,
            label: t(`${translationPath}linkedIn-link`),
            input: "linkedInLink",
          },
          {
            id: 2,
            label: t(`${translationPath}facebook-link`),
            input: "facebookLink",
          },
          {
            id: 3,
            label: t(`${translationPath}instagram-link`),
            input: "instagramLink",
          },

          {
            id: 4,
            label: t(`${translationPath}instagram-account-number`),
            input: "instagramAccountNumber",
          },
          {
            id: 5,
            label: t(`${translationPath}snapchat-link`),
            input: "snapchatLink",
          },
          {
            id: 6,
            label: t(`${translationPath}tiktok-link`),
            input: "tikTokLink",
          },
          {
            id: 7,
            label: t(`${translationPath}youTube-link`),
            input: "youtubeLink",
          },
          {
            id: 8,
            label: t(`${translationPath}x-link`),
            input: "xLink",
          },
        ]}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        defaultActions={[
          {
            enum: TableActions.editText.key,
            isDisabled: false,
            externalComponent: null,
          },
          {
            enum: TableActions.deleteText.key,
            isDisabled: false,
            externalComponent: null,
          },
        ]}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        activePage={filter.pageIndex}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        itemsPerPage={filter.pageSize}
        totalItems={data ? data.totalCount : 0}
      />
      {isMissingInfoMSGOpen && (
        <MessageConfirmDialog
          isDialogOpen={isMissingInfoMSGOpen}
          theme="warning"
          content={{
            heading: "delete-social-media-link",
            body:
              t(
                `${translationPath}are-you-sure-you-want-to-delete-social-media-logo`
              ) +
              "  " +
              (activeItem && activeItem.socialMediaLogoId),
          }}
          confirmButton={{
            label: t(`${translationPath}confirm`),
            handler: () => DeleteHandler(),
          }}
          cancelButton={{
            label: t(`${translationPath}discard`),
            handler: () => {
              setIsMissingInfoMSGOpen(false);
            },
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenEditDialog && (
        <SocialMediaLinkComponent
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isOpen={isOpenEditDialog}
          onClose={() => setIsOpenEditDialog(false)}
          onSave={(edit) => {
            if (edit) {
              setActiveItem(null);
              setIsOpenEditDialog(false);
              reloadData();
            }
          }}
          socialMediaLinkValues={socialMediaLink}
        />
      )}
    </div>
  );
};
