import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import ContactActivityItemTimeLine from "../ContactActivityItemTimeLine/ContactActivityItemTimeLine";
import { CustomPagination, NoDataFound } from "../../../../../../Components";
import { GetAllActivities } from "../../../../../../Services";

// Styles
import useStyles from "./styles";

function ContactActivities({ contactId }) {
  const styles = useStyles();

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 25,
  });
  const [inputValue, setInputValue] = useState(pagination.pageIndex);
  const [contactActivites, setContactActivites] = useState({
    result: [],
    totalCount: 0,
  });
  const [isContactActivitesLoading, setIsContactActivitesLoading] =
    useState(true);

  const getAllActivities = useCallback(async () => {
    setIsContactActivitesLoading(true);
    
    const body = {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      orderBy: 2,
      filterBy: "CreatedOn",
      contactId: contactId,
    };

    const res = await GetAllActivities(body);
    if (!(res && res.status && res.status !== 200)) {
      setContactActivites({
        result: res.result,
        totalCount: res.totalCount,
      });
    } else {
      setContactActivites({
        result: [],
        totalCount: 0,
      });
    }
    setIsContactActivitesLoading(false);
  });

  useEffect(() => {
    getAllActivities();
  }, [pagination]);

  return (
    <Box className={styles.container_Contact_Activity}>
      <Box className={styles.container_timeLine_Crad}>
        <ContactActivityItemTimeLine
          timelineItems={contactActivites?.result}
          totalCount={contactActivites?.totalCount}
        />
      </Box>
      
      <CustomPagination
        hideInMobile
        totalItems={contactActivites?.totalCount}
        itemsPerPage={pagination?.pageSize}
        currentPage={pagination?.pageIndex}
        inputValue={inputValue}
        setInputValue={setInputValue}
        onPageChange={(page) =>
          setPagination((prev) => ({ ...prev, pageIndex: page }))
        }
        onItemsPerPageChange={(items) =>
          setPagination((prev) => ({ ...prev, pageSize: items }))
        }
        isLoading={isContactActivitesLoading}
      />

      {contactActivites?.result?.length === 0 &&
        contactActivites?.totalCount === 0 &&
        !isContactActivitesLoading && <NoDataFound title={"activites"} />}
    </Box>
  );
}

export default ContactActivities;
