import React from "react";
import { CustomBadge, CustomCard } from "../../../../../../../Components";
import { Box, Divider, Typography } from "@material-ui/core";
import { useSelectedTheme, useTranslate } from "../../../../../../../Hooks";
// Styles
import { contactClassificationsStyles } from "./styles";

function ContactClassifications({ details }) {
 
  const styles = contactClassificationsStyles();
  
  const { translate } = useTranslate("NewContactsView");
  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <CustomCard borderRadius="xl"
      boxShadow="xs"
      borderColor="secondary"
      classes={styles.container}
    >
      <Box>
        <Typography className={styles.heading} variant="h3">
           {translate('contact-classifications')}
        </Typography>
        <Divider className={styles.divider} />
        <Box className={styles.classificationsContainer}>
          <Typography variant="h6" className={styles.classificationslable}>
          {translate('classifications')}:
          </Typography>
          {
            details.contactClassifications &&
            details?.contactClassifications?.map((classification, index) => (
              <CustomBadge
                key={index}
                label={classification?.lookupItemName}
                SizeVariant={'large'}
                BackgroundColor={palette.utility.gray_blue_50}
                BorderColor={palette.utility.gray_blue_200}
                Color={palette.text.secondary}
              />
            ))
          }
        </Box>
      </Box>
    </CustomCard>
  );
}
export default ContactClassifications;
