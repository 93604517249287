import React, { useEffect, useState } from 'react';
import { Inputs, Tables } from '../../../Components';
import { GetAllTenantLMSCourses } from '../../../Services';
import { showError } from '../../../Helper';
import { UserTrainingInsightsLocalePath } from '../UserTrainingInsights/i18n/UserTrainingInsightsLocale';
import { useTranslation } from 'react-i18next';
import { urlExpression } from '../../../Utils';
import { ButtonBase, Link } from '@material-ui/core';
import { LearningHubLocalePath } from './i18n/LearningHub';
import ShowMore from '../../../SharedComponents/ShowMore/ShowMore';
import { merge } from 'lodash';
import { useDebouncedAction } from '../../../Hooks/DebouncedAction';

const initialPagination = () => ({
  pageIndex: 1,
  pageSize: localStorage.getItem('Pagination') || 25,
});

const initialFilter = { categoryName: '', courseName: '' };

function LearningHub() {
  const [data, setData] = useState({ result: [], totalCount: 0 });
  const [pagination, setPagination] = useState(initialPagination);
  const [filter, setFilter] = useState({ categoryName: '', courseName: '' });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(LearningHubLocalePath);
  const getAllTenantLMSCourses = async () => {
    setLoading(true);
    try {
      const result = await GetAllTenantLMSCourses(merge(filter, pagination));
      setData(result);
    } catch (error) {
      showError(t('error_message', { ns: UserTrainingInsightsLocalePath }));
    } finally {
      setLoading(false);
    }
  };

  const debouncedGetAllTenantLMSCourses = useDebouncedAction(
    () => setPagination(initialPagination()),
    undefined,
    true
  );
  useEffect(() => {
    getAllTenantLMSCourses();
  }, [pagination]);

  useEffect(() => {
    debouncedGetAllTenantLMSCourses();
  }, [filter]);

  return (
    <>
      <div className='flex fj-end fa-center gap-1 px-3'>
        <Inputs
          wrapperClasses={'w-25'}
          label={'course-name'}
          onInputChanged={(e) => {
            e.persist();
            setFilter((prev) => ({ ...prev, courseName: e.target.value }));
          }}
          value={filter.courseName}
          parentTranslationPath={LearningHubLocalePath}
        />
        <Inputs
          wrapperClasses={'w-25'}
          label={'category'}
          onInputChanged={(e) => {
            e.persist();
            setFilter((prev) => ({ ...prev, categoryName: e.target.value }));
          }}
          value={filter.categoryName}
          parentTranslationPath={LearningHubLocalePath}
        />
        <div>
          <ButtonBase
            className='btns theme-solid bg-danger clear-all-btn'
            onClick={() => setFilter(initialFilter)}
          >
            <span className='mdi mdi-filter-remove m-1' />
            {t('clear_filters', { ns: UserTrainingInsightsLocalePath })}
          </ButtonBase>
        </div>
      </div>
      <div className=' p-5'>
        <Tables
          isLoading={loading}
          headerData={[
            { label: 'course-name', input: 'courseName' },
            { label: 'category', input: 'category' },
            {
              label: 'course-link',
              input: 'courseLink',
              component: (row) =>
                urlExpression.test(row.courseLink) ? (
                  <Link href={row.courseLink} target='_blank'>
                    {t('open_course')}
                  </Link>
                ) : (
                  ''
                ),
            },
            {
              label: 'description',
              input: 'description',
              component: (row) => (
                <ShowMore
                  text={row.description}
                  title={'description'}
                  parentTranslationPath={LearningHubLocalePath}
                />
              ),
            },
          ]}
          data={data.result}
          totalItems={data.totalCount}
          parentTranslationPath={LearningHubLocalePath}
          itemsPerPage={pagination.pageSize}
          activePage={pagination.pageIndex - 1}
          defaultActions={[]}
          onPageIndexChanged={(index) =>
            setPagination((prev) => ({ ...prev, pageIndex: index + 1 }))
          }
          onPageSizeChanged={(pageSize) =>
            setPagination((prev) => ({ ...prev, pageSize }))
          }
        />
      </div>
    </>
  );
}

export default LearningHub;
