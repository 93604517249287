import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from "react";
import { ButtonBase } from '@material-ui/core';
import Joi from 'joi';
import { useTranslation } from "react-i18next";
import {
  Inputs,
  AutocompleteComponent,
  Spinner,
} from "../../../../../Components";
import {
  GetParams,
  showError,
  showSuccess,
  getErrorByName 
} from "../../../../../Helper";
import { MapDialog } from '../../../FormBuilder/Dialogs/MapDialog' ; 
import { CreateBranchLocationAPI , GetBranchLocationsByDeveloperIdAPI , lookupItemsGet  , lookupItemsGetId } from "../../../../../Services";
import { BranchesLocationTable  } from './BranchesLocationComponents' ; 

export const BranchesLocation = ({
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [items , setItems] = useState({
    countries :[]  , 
    cities : [] , 
    districts : [] , 
    communities : [] ,
    subCommunities : [] , 
    allBranchesLocation : [{
      result : [] , 
      totalCount : 0 
    }]

  }) ; 
  const searchTimer = useRef(null);
  const [openMapDialog, setOpenMapDialog] = useState(false);

  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: "",
  });
  const [isLoading, setIsLoading] = useState({
    countries :false  , 
    cities :false, 
    districts : false , 
    communities : false ,
    subCommunities :false, 
    allBranchesLocation: false,
    addBranchesLocation: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false) ; 

  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    branchName: null,
    countryId: null,
    cityId: null , 
    districtId: null,
    communityId: null,
    subCommunityId: null,
    street : null ,
    buildingName: null , 
    buildingNumber: null,
    floorNumber:null,
    officeNumber: null,
    mapCoordinates:null,
    postalCode: null,
    poBox: null , 
    latitude : null  ,
    longitude : null 
  });

  const [defaultSelected, setDefaultSelected] = useState({
    country: null,
    city: null , 
    district: null,
    community: null,
    subCommunity: null,
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });
  const schema = Joi.object({
    branchName: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}branch-name-is-required`),
        'string.empty': t(`${translationPath}branch-name-is-required`),
        'string.required': t(`${translationPath}branch-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

    const getLookupItemsByName = async (lookupTypeName , key ,searchValue , lookupParentId , lookupTypeId ) => {

      setIsLoading((item) => ({ ...item, [lookupTypeName]: true }));
      const result = await lookupItemsGet({
        lookupTypeId : lookupTypeId, 
        pageSize: 100,
        pageIndex: 1,
        searchedItem: searchValue || null   , 
        lookupParentId: lookupParentId  
      });
      if (!(result && result.status && result.status !== 200)) {
          setItems((item) => ({ ...item, [key]: (result && result.result) || []   }));
      } 
      else  setItems((item) => ({ ...item, [key]: []}));
      setIsLoading((item) => ({ ...item, [lookupTypeName]: false }));
  
    };


    const onSelectAddressHandler = (key, value, selectedValues) => {
      let newState = null;
      let newSelected = null;

      if (key === "countryId") {
        newState = {
          ...state,
          countryId: value,
          cityId: null,
          districtId: null,
          communityId: null,
          subCommunityId: null,
        };
        newSelected = {
          ...selected,
          country: selectedValues,
          city: null,
          district: null,
          community: null,
          subCommunity: null,
        };
        getLookupItemsByName('districts', 'districts' , null  ,  null , 18 );
        getLookupItemsByName('communities', 'communities' , null  ,  null , 19 );
        getLookupItemsByName('subCommunities', 'subCommunities' , null  ,  null , 20 );

      } else if (key === "cityId") {
        newState = {
          ...state,
          // countryId :   ,
          cityId: value,
          districtId: null,
          communityId: null,
          subCommunityId: null,
        };
        newSelected = {
          ...selected,
          //  country :   ,
          city: selectedValues,
          district: null,
          community: null,
          subCommunity: null,
        };
        getLookupItemsByName('communities', 'communities' , null  ,  null , 19 );
        getLookupItemsByName('subCommunities', 'subCommunities' , null  ,  null , 20 );
      } else if (key === "districtId") {
        newState = {
          ...state,
          // countryId :   ,
          //  cityId : null ,
          districtId: value,
          communityId: null,
          subCommunityId: null,
        };
        newSelected = {
          ...selected,
          // country :   ,
          // city : null ,
          district: selectedValues,
          community: null,
          subCommunity: null,
        };
        getLookupItemsByName('subCommunity', 'subCommunities' , null  ,  null , 20 );
      } else if (key === "communityId") {
        newState = {
          ...state,
          // countryId : value  ,
          // cityId : null ,
          // districtId : null ,
          communityId: value,
          subCommunityId: null,
        };
        newSelected = {
          ...selected,
          // country : selectedValues  ,
          // city : null ,
          // district : null ,
          community: selectedValues,
          subCommunity: null,
        };
      } else if (key === "subCommunityId") {
        newState = {
          ...state,
          // countryId : value  ,
          // cityId : null ,
          // districtId : null ,
          // communityId : null ,
          subCommunityId: value,
        };
        newSelected = {
          ...selected,
          // country : selectedValues  ,
          // city : null ,
          // district : null ,
          // community : selectedValues ,
          subCommunity: selectedValues,
        };
      }
      setState({ id:'edit', value: newState });
      setSelected({ id: 'edit', value: newSelected });
      
    };

  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else
     {
      if(key === 'countryId' || key === 'cityId'  || key === 'districtId'  || key === 'communityId'  || key === 'subCommunityId'  ) 
      {
        onSelectAddressHandler(key ,values ,selectedValues) ; 
      }
      else {
        setState({ id: [key], value: values });
        setSelected({ id: [selectedObj], value: selectedValues });
      }
     
    }
  };

  const lookupItemsGetIdAPI = async (key , key2 , lookupParentId , lookupTypeId ) => {

    setIsLoading((item) => ({ ...item, [key]: true }));
    const result = await lookupItemsGetId({
      lookupTypeId : lookupTypeId, 
      lookupParentId: lookupParentId  
    });
    if (!(result && result.status && result.status !== 200)) {
        setItems((item) => ({ ...item, [key2]: (result) || []   }));
    } 
    else  setItems((item) => ({ ...item, [key2]: []}));
    setIsLoading((item) => ({ ...item, [key]: false }));

  };
   
  const GetBranchLocationsByDeveloperId = async (search) => {
    setIsLoading((item) => ({ ...item, allBranchesLocation: true }));
    const rs = await GetBranchLocationsByDeveloperIdAPI(
      +GetParams("id"),
      {
        pageIndex: filter.pageIndex + 1,
        pageSize: filter.pageSize,
        name: search ,
      }
    );
    if (!rs) {
      setItems((item) => ({
        ...item,
        allBranchesLocation: { result: [], totalCount: 0 },
      }));
      return;
    } else {
      setItems((item) => ({
        ...item,
        allBranchesLocation: {
          result: rs?.result || [],
          totalCount: rs.totalCount || 0,
        },
      }));
    }
    setIsLoading((item) => ({ ...item, allBranchesLocation: false }));
  };
  
  const reloadData = () => {
    setState({ id: "edit", value: defaultState });
    setSelected({ id: "edit", value: defaultSelected });
    GetBranchLocationsByDeveloperId();
  };


  const CreateBranchLocationHandler = useCallback(async () => {
    setIsSubmitted(true);

    setIsLoading((item) => ({ ...item, addBranchesLocation: true }));
    const body = { ...state };
    const res = await CreateBranchLocationAPI(
      body
    );
    setIsLoading((item) => ({ ...item, addBranchesLocation: false }));
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t`${translationPath}branches-location-created-successfully`
      );
      reloadData() ;
    } else showError(t`${translationPath}branches-location-create-failed`);
  }, [state]);


  useEffect(() => {
    reloadData();
  }, [filter]);


  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <Spinner isActive={isLoading.addBranchesLocation || isLoading.allBranchesLocation} />
        
        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"branch-NameRef"}
                labelValue={"branch-name"}
                type={"text"}
                value={state.branchName || ''}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("branchName", value);
                }}
                inputPlaceholder={"enter-branch-name"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                helperText={getErrorByName(schema, 'branchName').message}
                error={getErrorByName(schema, 'branchName').error}
                isWithError
                isSubmitted={isSubmitted}
              />
            </div>
            
            <div className="px-2 item">
            <AutocompleteComponent
                key={`country`}
                selectedValues={selected.country}
                idRef={`countryRef`}
                multiple={false}
                inputPlaceholder={t("select-country")}
                data={items &&  items.countries}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, newValue) => {
                  onChangeHandler("countryId", (newValue && newValue.lookupItemId), 'country' , newValue );
                  lookupItemsGetIdAPI('cities', 'cities'  , (newValue?.lookupItemId && +newValue.lookupItemId) || null , 17 );
                }}
                isLoading={isLoading.countries}
                withLoader
                withoutSearchButton
                labelValue={"country"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.countries?.length === 0 )
                 getLookupItemsByName('countries' , 'countries' , null , null , 16  );
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getLookupItemsByName('countries', 'countries' , value  ,  null , 16 );
                  }, 700);
                }}
              />
            </div>
            <div className="px-2 item">
            <AutocompleteComponent
                key={`city`}
                selectedValues={selected.city}
                idRef={`cityRef`}
                multiple={false}
                inputPlaceholder={t("select-city")}
                data={items &&  items.cities}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                isLoading={isLoading.cities}
                withLoader
                withoutSearchButton
                labelValue={"city"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                // onOpen={() => {
                //   if(items?.cities?.length === 0 )
                //  getLookupItemsByName('city' , 'cities' , null , null , 17 );
                // }}
                onChange={(e, newValue) => {
                  onChangeHandler("cityId", (newValue && newValue.lookupItemId), 'city' , newValue );
                  lookupItemsGetIdAPI('districts', 'districts' ,  (newValue?.lookupItemId && +newValue.lookupItemId) || null , 18 );

                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getLookupItemsByName('cities', 'cities' , value , state.countryId , 17 );
                  }, 700);
                }}
              />
            </div>
            <div className="px-2 item">
            <AutocompleteComponent
                key={`district`}
                selectedValues={selected.district}
                idRef={`districtRef`}
                multiple={false}
                inputPlaceholder={t("select-district")}
                data={items &&  items.districts}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                isLoading={isLoading.districts}
                withLoader
                withoutSearchButton
                labelValue={"district"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                // onOpen={() => {
                //   if(items?.districts?.length === 0 )
                //  getLookupItemsByName('district' , 'districts' , null , null , 18);
                // }}
                onChange={(e, newValue) => {
                  onChangeHandler("districtId", (newValue && newValue.lookupItemId), 'district' , newValue );
                  lookupItemsGetIdAPI('communities',  'communities' , (newValue?.lookupItemId && +newValue.lookupItemId) || null , 19);

                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getLookupItemsByName('districts', 'districts' , value , state.cityId , 18 );
                  }, 700);
                }}
              />
            </div>
            <div className="px-2 item">
            <AutocompleteComponent
                key={`community`}
                selectedValues={selected.community}
                idRef={`communityRef`}
                multiple={false}
                inputPlaceholder={t("community")}
                data={items &&  items.communities}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                isLoading={isLoading.communities}
                withLoader
                withoutSearchButton
                labelValue={"community"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                // onOpen={() => {
                //   if(items?.communities?.length === 0 )
                //  getLookupItemsByName('community' , 'communities' , null ,null , 19);
                // }}
                onChange={(e, newValue) => {
                  onChangeHandler("communityId", (newValue && newValue.lookupItemId), 'community' , newValue );
                  lookupItemsGetIdAPI('subCommunities', 'subCommunities'  , (newValue?.lookupItemId && +newValue.lookupItemId) || null , 20);

                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getLookupItemsByName('communities' , 'communities' , value , state.districtId , 19);
                  }, 700);
                }}
              />
            </div>
            <div className="px-2 item">
            <AutocompleteComponent
                key={`SubCommunity`}
                selectedValues={selected.subCommunity}
                idRef={`subCommunityRef`}
                multiple={false}
                inputPlaceholder={t("select-sub-community")}
                data={items &&  items.subCommunities}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                isLoading={isLoading.subCommunities}
                withLoader
                withoutSearchButton
                labelValue={"subCommunity"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                // onOpen={() => {
                //   if(items?.subCommunities?.length === 0 )
                //  getLookupItemsByName('subcommunity' , 'subCommunities' , null , null , 20);
                // }}
                onChange={(e, newValue) => {
                  onChangeHandler("subCommunityId", (newValue && newValue.lookupItemId), 'subCommunity' , newValue );

                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getLookupItemsByName('subCommunities' , 'subCommunities' , value , state.communityId , 20);
                  }, 700);
                }}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"StreetRef"}
                labelValue={"street"}
                value={state.street || ''}
                type={"text"}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("street", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"BuildingNameRef"}
                labelValue={"building-name"}
                type={"text"}
                value={state.buildingName || ''}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("buildingName", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"BuildingNumberRef"}
                labelValue={"building-number"}
                type={"text"}
                value={state.buildingNumber || ''}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("buildingNumber", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"FloorNumberRef"}
                labelValue={"floor-number"}
                type={"text"}
                value={state.floorNumber || ''}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("floorNumber", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"OfficeNumberRef"}
                labelValue={"office-number"}
                type={"text"}
                value={state.officeNumber || ''}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("officeNumber", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
               <Inputs
              isAttachedInput
              idRef={"MapCoordinatesRef"}
              labelValue={"map-coordinates"}
              isDisabled
              value={state.mapCoordinates || ''}
              onInputChanged={()=> console.log('')}
              buttonOptions={
              ( {
                className: "btns-icon theme-outline c-blue-lighter",
                iconClasses: "mdi mdi-map-marker",
                isDisabled:  false,
                isRequired: false,
                onActionClicked: () => {
                  setOpenMapDialog(true);
                },
              }) ||
              undefined
            }
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
           />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"Postal-ZIPCodeRef"}
                labelValue={"postal-zip-code"}
                type={"text"}
                value={state.postalCode || ''}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("postalCode", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"poBoxRef"}
                labelValue={"po-box"}
                type={"text"}
                value={state.poBox || ''}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("poBox", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="mt-3 addBtn">
            <ButtonBase
            className='btns theme-solid'
            disabled={schema.error || isLoading.addBranchesLocation}
            onClick={() => 
            {
              CreateBranchLocationHandler() 
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}add-branch-location`)}
          </ButtonBase>
          </div>
          </div>
        </div>
      </div>
      <BranchesLocationTable 
         translationPath={translationPath}
         parentTranslationPath={parentTranslationPath}
         data={items.allBranchesLocation}
         filter={filter}
         setFilter={setFilter}
         reloadData={reloadData}
      />
       {openMapDialog && (
        <MapDialog
          open={openMapDialog}
          onChange={(e) => {
              setSelected({id : 'edit' , value : {...selected ,latitude :e.latitude , longitude : e.longitude }})
              setState({'id' : 'mapCoordinates' , value :(e.latitude ? `${e.latitude},${e.longitude}` :null)})
          }}
          initialState={selected}
          closeDialog={() => {
            setOpenMapDialog(false);
          }}
        />
      )}
      
    </div>
  );
};
