import React from "react";
import moment from "moment";
import { Typography, Divider, Box } from "@material-ui/core";
import { CustomCard } from "../../../../../../../Components";
import { useTranslate } from "../../../../../../../Hooks";
// Styles
import { visaDetailsStyles } from "./styles";


function VisaDetails({ details }) {
  const countryCode = details?.visa_issuer_country?.lookupItemCode;
  const styles = visaDetailsStyles({ countryCode });
  const { translate } = useTranslate("NewContactsView");
  
  return (
    <CustomCard
      borderRadius="xl"
      boxShadow="xs"
      borderColor="secondary"
      classes={styles.container}
    >
      <Typography className={styles.heading} variant="h3">
      {translate('visa-details')}
      </Typography>
      <Typography className={styles.subHeading} variant="h3">
        {translate('join-the-conversation')}:
      </Typography>
      <Divider className={styles.divider} />
      <Box className={styles.infoContainer}>
        <Box className={styles.dualPane}>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">{translate('visa-no')}:</Typography>
            <Typography className={styles.infoValue} variant="body1">{details?.visa_no ?? "N/A"}</Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">{translate('visa-type')}:</Typography>
            <Typography className={styles.infoValue} variant="body1">{details?.visa_type?.lookupItemName ?? "N/A"}</Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">{translate('visa-issuer-country')}:</Typography>
            <Box display="flex" alignItems="center">
              {countryCode && <Box className={styles.customFlag} />}
              <Typography className={styles.infoValue} variant="body1">{details?.visa_issuer_country?.lookupItemName ?? "N/A"}</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.dualPane}>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">{translate('visa-issue-date')}:</Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.visa_issue_date
                  ? moment(details?.visa_issue_date).format("DD/MM/YYYY")
                  : "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">{translate('visa-expiry-date')}:</Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.visa_expiry_date
                  ? moment(details?.visa_expiry_date).format("DD/MM/YYYY")
                  : "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">{translate('tax-registration-no')}:</Typography>
            <Typography className={styles.infoValue} variant="body1">{details?.tax_registration_no ?? "N/A"}</Typography>
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
}

export default VisaDetails;
