import {
  AgreementDetails,
  AuditAndCompliance,
  DeveloperContactDocumentsComponent,
  CommissionDetailsByAgreementView,
} from "../AgreementsAndCompliance";
export const AgreementsAndComplianceVerticalTabsData = [
  {
    label: "agreement-details",
    component: AgreementDetails,
  },
  {
    label: "audit-and-compliance",
    component: AuditAndCompliance,
  },
  // {
  //   label: "commission-details",
  //   component: CommissionDetailsByAgreementView,
  // },

  {
    label: "documents",
    component: DeveloperContactDocumentsComponent,
  },
];
