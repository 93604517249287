import { PowerBiEnumdemo } from "../Views/Home/Dashboard/BIConfigDataBase/PowerBiEnumdemo";
import { PowerBiEnumdev } from "../Views/Home/Dashboard/BIConfigDataBase/PowerBiEnumdev";
import { PowerBiEnumproduction } from "../Views/Home/Dashboard/BIConfigDataBase/PowerBiEnumproduction";
import { PowerBiEnumstg } from "../Views/Home/Dashboard/BIConfigDataBase/PowerBiEnumstg";
import { PowerBiEnumassets } from "../Views/Home/Dashboard/BIConfigDataBase/PowerBiEnumassets";
import { PowerBiEnumDubai } from "../Views/Home/Dashboard/BIConfigDataBase/PowerBiEnumDubai";

// eslint-disable-next-line import/no-mutable-exports
let PowerBiConf = PowerBiEnumassets;

// switch (process.env.REACT_APP_CONFIG) {
//   case "staging":
//     PowerBiConf = PowerBiEnumstg;
//     break;
//   case "production":
//     PowerBiConf = PowerBiEnumproduction;
//     break;
//   case "demo":
//     PowerBiConf = PowerBiEnumdemo;
//     break;
//   case "assets":
//     PowerBiConf = PowerBiEnumassets;
//     break;
//   case "dubai":
//     PowerBiConf = PowerBiEnumDubai;
//     break;
//   case "dubaiCRMConfig":
//     PowerBiConf = PowerBiEnumDubai;
//     break;
//   default:
//     PowerBiConf = PowerBiEnumdev;
//     break;
// }

export { PowerBiConf as PowerBiEnum };
