export const PowerBiEnumassets = {
  salesToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8c81de09-4fc8-4d21-bb77-4728702afb93',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8c81de09-4fc8-4d21-bb77-4728702afb93&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newsalesToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8c81de09-4fc8-4d21-bb77-4728702afb93',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8c81de09-4fc8-4d21-bb77-4728702afb93&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  teamleadToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f5b41fe0-31bb-4731-bd6d-88f7e149f46e',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f5b41fe0-31bb-4731-bd6d-88f7e149f46e&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  agentToken: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'f6b066a7-fcf3-4d6f-b078-0b84170864ad',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f6b066a7-fcf3-4d6f-b078-0b84170864ad&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  leaseAgentToken: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '8651d915-9e93-4ed1-96d5-5b159fb70702',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8651d915-9e93-4ed1-96d5-5b159fb70702&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  leasingToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '2c42857e-2ed6-4e51-8bbd-e5670ec2df7d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=2c42857e-2ed6-4e51-8bbd-e5670ec2df7d&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  NewleasingToken: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '128803aa-b880-437c-bf8d-fb71cefa7ba7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=128803aa-b880-437c-bf8d-fb71cefa7ba7&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  callCenter: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '0667ec04-e216-4397-a91a-7e910126f0c2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=0667ec04-e216-4397-a91a-7e910126f0c2&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newcallCenter: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '485bc438-3e36-457f-9579-3d2a4ee224f3',
    url: 'https://app.powerbi.com/reportEmbed?reportId=485bc438-3e36-457f-9579-3d2a4ee224f3&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  marketing: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8bfa5689-bc70-4027-a57d-98100119ab29',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8bfa5689-bc70-4027-a57d-98100119ab29&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newmarketing: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: "61bdca1b-20bc-4ff9-aa78-f2bc08d6813e",
    url: "https://app.powerbi.com/reportEmbed?reportId=61bdca1b-20bc-4ff9-aa78-f2bc08d6813e&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
  },
  psidaily: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '9fc2cabd-c713-4ff5-a26f-e114d7b0e915',
    url: 'https://app.powerbi.com/reportEmbed?reportId=9fc2cabd-c713-4ff5-a26f-e114d7b0e915&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  Newpsidaily: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '82d2eced-4c44-45e4-b2f4-ae4eee282fb2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=82d2eced-4c44-45e4-b2f4-ae4eee282fb2&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  saleslistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '34e7829b-ed37-4501-b6c1-4bccdcb812c6',
    url: 'https://app.powerbi.com/reportEmbed?reportId=34e7829b-ed37-4501-b6c1-4bccdcb812c6&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newsaleslistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '62302fde-3c11-450e-b25d-308f7e1838c8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=62302fde-3c11-450e-b25d-308f7e1838c8&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  SalesListingUnitsDate: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '0d20da73-5cd1-4425-8898-2fce782810e8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=0d20da73-5cd1-4425-8898-2fce782810e8&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseListingUnitsDate: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '99ebebf5-b33b-4c6e-93ce-474fd0d8186d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=99ebebf5-b33b-4c6e-93ce-474fd0d8186d&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  leaselistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f923e598-53d8-4195-ac75-25e6c90ce3ed',
    url: 'https://app.powerbi.com/reportEmbed?reportId=ff378a3e-c53d-4211-b77a-9d3630ddb4d7&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  Newleaselistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'd310adc4-99e0-4d28-af32-d3d854624658',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d310adc4-99e0-4d28-af32-d3d854624658&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  accounts: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '35d50d8e-9ddc-4696-b978-519bdba80585',
    url: 'https://app.powerbi.com/reportEmbed?reportId=35d50d8e-9ddc-4696-b978-519bdba80585&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  intentionalsales: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '901fbc6e-3fa4-45fe-8bbd-bd8aedc60f72',
    url: 'https://app.powerbi.com/reportEmbed?reportId=901fbc6e-3fa4-45fe-8bbd-bd8aedc60f72&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  dubai_lease_listing_admin: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '6f4bc7fa-d9c7-42dd-b3b3-afeb0021ce9d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6f4bc7fa-d9c7-42dd-b3b3-afeb0021ce9d&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  dubai_lease_listing_agent: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'c6dfbed6-2122-4590-ae8a-a272fd4b42e5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=c6dfbed6-2122-4590-ae8a-a272fd4b42e5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  dubai_sales_listing_admin: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '98da1945-916c-495e-9f4a-93b751c67788',
    url: 'https://app.powerbi.com/reportEmbed?reportId=98da1945-916c-495e-9f4a-93b751c67788&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  dubai_sales_listing_agent: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'a4ef57e8-568f-4eea-ad5e-97261b8a05b5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a4ef57e8-568f-4eea-ad5e-97261b8a05b5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_1: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'fd1f176f-0d01-4a24-91da-7076cb0d5846',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fd1f176f-0d01-4a24-91da-7076cb0d5846&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_2: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'ee738ce5-7473-4e51-b06e-fe31ea58e727',
    url: 'https://app.powerbi.com/reportEmbed?reportId=ee738ce5-7473-4e51-b06e-fe31ea58e727&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  tab_3: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8d99ee48-042c-4853-977b-bbc587770ac2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8d99ee48-042c-4853-977b-bbc587770ac2&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_4: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '8d732e20-926f-4850-9c2d-77be14f0cf8a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8d732e20-926f-4850-9c2d-77be14f0cf8a&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  tab_5: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '85b7e79a-9256-4592-affa-0ef44a7d10a3',
    url: 'https://app.powerbi.com/reportEmbed?reportId=85b7e79a-9256-4592-affa-0ef44a7d10a3&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  tab_6: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'b169e873-2d61-4362-897e-cc30c6e37c9e',
    url: 'https://app.powerbi.com/reportEmbed?reportId=b169e873-2d61-4362-897e-cc30c6e37c9e&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  tab_7: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'a4ccc8a3-8537-44b7-ac2a-114793be4055',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a4ccc8a3-8537-44b7-ac2a-114793be4055&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  tab_8: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'b9f074a6-fd8e-492c-b468-ce9461859c82',
    url: 'https://app.powerbi.com/reportEmbed?reportId=b9f074a6-fd8e-492c-b468-ce9461859c82&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  tab_9: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '30550668-13db-41b0-9d12-3e31674eec55',
    url: 'https://app.powerbi.com/reportEmbed?reportId=30550668-13db-41b0-9d12-3e31674eec55&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  tab_10: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'd9ef8d4d-c5d2-4e07-94a7-d248f2ddd172',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d9ef8d4d-c5d2-4e07-94a7-d248f2ddd172&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  MarketingClientsSegmentation: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '2cd3edfb-e284-4788-8bad-278ebc382749',
    url: 'https://app.powerbi.com/reportEmbed?reportId=2cd3edfb-e284-4788-8bad-278ebc382749&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Branches: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '00a2c914-739a-47e0-ab75-710653e4e40f',
    url: 'https://app.powerbi.com/reportEmbed?reportId=00a2c914-739a-47e0-ab75-710653e4e40f&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Salesdirector: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '6ca9e9db-9a0b-40f1-9262-82380d5a387b',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6ca9e9db-9a0b-40f1-9262-82380d5a387b&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  Leasedirector: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'bb5e7954-b3cc-4a5f-aeb7-1d4930a2dc73',
    url: 'https://app.powerbi.com/reportEmbed?reportId=bb5e7954-b3cc-4a5f-aeb7-1d4930a2dc73&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  Leasedetails: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'a220bcf9-7d65-4427-9fb3-2e96d64adbd8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a220bcf9-7d65-4427-9fb3-2e96d64adbd8&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeadOwner: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '43e451f4-190d-4d68-8359-58ab6036703d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=43e451f4-190d-4d68-8359-58ab6036703d&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  UserLogin: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '2f4da476-f136-455a-976b-cc7e8d30bf4a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=2f4da476-f136-455a-976b-cc7e8d30bf4a&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  ActivatedLeadid: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '5049cf40-dc6c-48e0-ac16-51138fc64d71',
    url: 'https://app.powerbi.com/reportEmbed?reportId=5049cf40-dc6c-48e0-ac16-51138fc64d71&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  CustomersRisk: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '77559367-2bf3-4cda-bc75-2ef4caa1c496',
    url: 'https://app.powerbi.com/reportEmbed?reportId=77559367-2bf3-4cda-bc75-2ef4caa1c496&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  PropertyFinder: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'd3ccbcbb-39de-4ede-91bb-b18ad6993561',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d3ccbcbb-39de-4ede-91bb-b18ad6993561&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  Bayut: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '637bb277-6f53-4b70-b7b4-20791a8bc9e2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=637bb277-6f53-4b70-b7b4-20791a8bc9e2&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  SalesAgentplus: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'a0fe6954-bbc9-413e-8d63-356872364b07',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a0fe6954-bbc9-413e-8d63-356872364b07&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  SalesTeamLeadplus: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '8f974747-d153-4d5d-af4a-a99dc3bff795',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8f974747-d153-4d5d-af4a-a99dc3bff795&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  SalesDirectorplus: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '843a867f-dc08-4df5-b3f8-d47155036fc4',
    url: 'https://app.powerbi.com/reportEmbed?reportId=843a867f-dc08-4df5-b3f8-d47155036fc4&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseDirectorplus: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '9c6b9c52-ef92-4026-b2fc-aff5a6344a5c',
    url: 'https://app.powerbi.com/reportEmbed?reportId=9c6b9c52-ef92-4026-b2fc-aff5a6344a5c&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  SalesDirectorplus_Date: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '211c80c0-c85d-4724-bb97-1fcd41117925',
    url: 'https://app.powerbi.com/reportEmbed?reportId=211c80c0-c85d-4724-bb97-1fcd41117925&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  
  LeaseDirectorplus_Date: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '1e05e47d-0498-45dc-a9cf-8fe4c92631ee',
    url: 'https://app.powerbi.com/reportEmbed?reportId=1e05e47d-0498-45dc-a9cf-8fe4c92631ee&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseAgentplus: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '26968f44-1c9c-4a0b-ba76-ad62204d03ea',
    url: 'https://app.powerbi.com/reportEmbed?reportId=26968f44-1c9c-4a0b-ba76-ad62204d03ea&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseTeamLeadplus: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '8c6b9517-8807-4b3c-a85f-b96b75003459',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8c6b9517-8807-4b3c-a85f-b96b75003459&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  SalesTeamLeadDate: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'b190095f-aff5-4436-b8bc-2bf30f6df79d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=b190095f-aff5-4436-b8bc-2bf30f6df79d&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseTeamLeadDate: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '128803aa-b880-437c-bf8d-fb71cefa7ba7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=128803aa-b880-437c-bf8d-fb71cefa7ba7&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  RotationScheme: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '68a42ac8-f272-49d5-93ab-0ba5fb4abcba',
    url: 'https://app.powerbi.com/reportEmbed?reportId=68a42ac8-f272-49d5-93ab-0ba5fb4abcba&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  CampaignCost: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'fa2fe730-fb7a-4cfa-bbdc-c7513e50b5c7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fa2fe730-fb7a-4cfa-bbdc-c7513e50b5c7&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  SegmentationLeadBranch: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '4076ea50-943f-4ebc-a2e5-969636c267ad',
    url: 'https://app.powerbi.com/reportEmbed?reportId=4076ea50-943f-4ebc-a2e5-969636c267ad&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  SegmentationContactBranch: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '142360bb-5681-4724-b6d5-a9d41a66af94',
    url: 'https://app.powerbi.com/reportEmbed?reportId=142360bb-5681-4724-b6d5-a9d41a66af94&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  SegmentationOwnerBranch: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '76b6f561-e683-4fd5-ae4d-3a27c5a5a98a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=76b6f561-e683-4fd5-ae4d-3a27c5a5a98a&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  callCenterDate: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '485bc438-3e36-457f-9579-3d2a4ee224f3',
    url: 'https://app.powerbi.com/reportEmbed?reportId=485bc438-3e36-457f-9579-3d2a4ee224f3&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeadAutomation: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'e2e38f44-5bc8-416e-baf9-d25ed02b6418',
    url: 'https://app.powerbi.com/reportEmbed?reportId=e2e38f44-5bc8-416e-baf9-d25ed02b6418&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  UnqualifiedLeads: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'e7321169-8a04-4563-815a-adaa455c79a4',
    url: 'https://app.powerbi.com/reportEmbed?reportId=e7321169-8a04-4563-815a-adaa455c79a4&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  InternationalSalesYTDMTD: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '4583365e-9dc8-4dd2-ac58-b54053fc1eed',
    url: 'https://app.powerbi.com/reportEmbed?reportId=4583365e-9dc8-4dd2-ac58-b54053fc1eed&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  UsersDetails: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '9a6b1233-40ff-4586-bd86-0de8417eba21',
    url: 'https://app.powerbi.com/reportEmbed?reportId=9a6b1233-40ff-4586-bd86-0de8417eba21&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  PortalsCampaignChart: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '99ae0358-11e3-4a1c-a55f-37818bc82203',
    url: 'https://app.powerbi.com/reportEmbed?reportId=99ae0358-11e3-4a1c-a55f-37818bc82203&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  ListingAllUnits: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '37572c52-29e9-4bfa-97e3-c0c1ba97058d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=37572c52-29e9-4bfa-97e3-c0c1ba97058d&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  AML: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '20931335-9bf5-4af5-8814-26c72cf2a62a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=20931335-9bf5-4af5-8814-26c72cf2a62a&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Projecttask: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'e819a4c7-a15c-4914-81fc-83e6842bb6a1',
    url: 'https://app.powerbi.com/reportEmbed?reportId=e819a4c7-a15c-4914-81fc-83e6842bb6a1&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  CampaignRequest: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '208a6c94-fad4-41df-bb33-af0372b81663',
    url: 'https://app.powerbi.com/reportEmbed?reportId=208a6c94-fad4-41df-bb33-af0372b81663&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeadPool: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '7ad35e5a-863c-4d17-8b34-f32a2c3c43a0',
    url: 'https://app.powerbi.com/reportEmbed?reportId=7ad35e5a-863c-4d17-8b34-f32a2c3c43a0&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeadFromPool: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '6d50b3f4-7048-4e0c-b6dc-c2a6869d2d35',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6d50b3f4-7048-4e0c-b6dc-c2a6869d2d35&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  AdminLeadFromPool: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'ad98f237-4ded-4915-a82d-0c53329b0bd8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=ad98f237-4ded-4915-a82d-0c53329b0bd8&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  zeromatching: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: 'f3dbffc8-3782-475b-8348-777e6f2576ae',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f3dbffc8-3782-475b-8348-777e6f2576ae&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  developerProperty: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '62808c79-3e19-438d-9757-e7c1a4605c80',
    url: 'https://app.powerbi.com/reportEmbed?reportId=62808c79-3e19-438d-9757-e7c1a4605c80&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  activity: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '17bac9b9-8b43-413f-a7c5-e6c9290ff9be',
    url: 'https://app.powerbi.com/reportEmbed?reportId=17bac9b9-8b43-413f-a7c5-e6c9290ff9be&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Ai_Call: {
    groupid: 'c7a64b5b-15c0-415c-a951-b8f42f5480ee',
    reportid: '936fb2c6-95b5-4005-b2a8-25d6b7afac1e',
    url: 'https://app.powerbi.com/reportEmbed?reportId=936fb2c6-95b5-4005-b2a8-25d6b7afac1e&groupId=c7a64b5b-15c0-415c-a951-b8f42f5480ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
};

