import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  MarketingAgentAutocomplete,
  TitleDescriptionTemplateControls,
  UspAutocomplete,
} from './controls';
import {
  CheckboxesComponent,
  RadiosGroupComponent,
  PermissionsComponent,
  Inputs,
} from '../../../../../../../../../Components';
import { UnitsOperationTypeEnum } from '../../../../../../../../../Enums';
import { CheckPendingRequestForUser, unitDetailsGet } from '../../../../../../../../../Services';
import { GetParams, returnPropsByPermissions } from '../../../../../../../../../Helper';
import { LeadOwnerUnitSalePermissions } from '../../../../../../../../../Permissions';
import {
  StaticLookupsIds
} from '../../../../../../../../../assets/json/StaticLookupsIds';
import { useSelector } from 'react-redux';

export const Descriptions = ({
  state,
  schema,
  onStateChanged,
  isMarketAsADifferentAgent,
  onIsMarketAsADifferentAgent,
  isSubmitted,
  parentTranslationPath,
  translationPath,
  activeItem,
  isNeedApprovalMessageInMarketing,
  setIsDialogOpen,
  approvalResponse,
  setIsPendingRequest,
  isPendingRequest,
  setIsSubmitted,
  saveHandler
}) => {

  const { t } = useTranslation([parentTranslationPath]);
  const [isSaleAndRent, setIsSaleAndRent] = useState((+GetParams('operationType') === UnitsOperationTypeEnum.rentAndSale.key));

  const isNew  =  useMemo(()=> !state.titleEn && !state.descriptionEn,[])

  const getFeaturedUnitWithPermissions = () => {
    // eslint-disable-next-line prefer-const
    let list = [];
    if (returnPropsByPermissions(LeadOwnerUnitSalePermissions.EditFeaturedUnitMarketing.permissionsId)) {
      list.push({
        key: 'isFeatureUnit',
        value: 'featured-unit',
      });
    }
    if (returnPropsByPermissions(LeadOwnerUnitSalePermissions.EditHotDealMarketing.permissionsId)) {
      list.push({
        key: 'isHotDealUnit',
        value: 'hot-deal-unit',
      });
    }
    list.push({
      key: "isDealOfTheDay",
      value: "deal-of-the-day",
    });
    return list;
  };

  useEffect(() => {
    if (activeItem && activeItem.operation_type && activeItem.operation_type.lookupItemId === UnitsOperationTypeEnum.rentAndSale.key)
      setIsSaleAndRent(true);
    else
      setIsSaleAndRent(false);
  }, [activeItem]);
  const loginResponse = useSelector((state) => state.login.loginResponse);

  const checkPendingRequestForUser = async () => {
    const userId = loginResponse.userId;
    const unitId = state.unitId;
    const res = await CheckPendingRequestForUser(userId, unitId);
    if (!(res && res.status && res.status !== 200)) {
      setIsPendingRequest(res);
      return res;
    }
  };
  useEffect(() => {
    checkPendingRequestForUser();
  }, []);

  return (
    <div className='marketing-description-wrapper presentational-wrapper'>
      <div className='title-wrapper'>
        <span className='title-text'>{t(`${translationPath}description`)}</span>
      </div>
      <PermissionsComponent
        permissionsList={Object.values(LeadOwnerUnitSalePermissions)}
        permissionsId={
          LeadOwnerUnitSalePermissions.EditUspMarketing.permissionsId
        }
      >
        <UspAutocomplete
          state={state}
          schema={schema}
          onStateChanged={onStateChanged}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />

      </PermissionsComponent>

      <div className='form-item'>
        <RadiosGroupComponent
          idRef='isMarketAsADifferentAgentRef'
          labelValue='is-market-as-a-different-agent'
          data={[
            {
              key: true,
              value: 'yes',
            },
            {
              key: false,
              value: 'no',
            },
          ]}
          value={isMarketAsADifferentAgent}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput='value'
          valueInput='key'
          onSelectedRadioChanged={(e, newValue) => {
            if (state.agentsId && onStateChanged)
              onStateChanged({ id: 'agentsId', value: null });
            if (onIsMarketAsADifferentAgent)
              onIsMarketAsADifferentAgent(newValue === 'true');
          }}
        />
      </div>
      {isMarketAsADifferentAgent && (

        <MarketingAgentAutocomplete
          state={state}
          schema={schema}
          onStateChanged={onStateChanged}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}

      <TitleDescriptionTemplateControls
        state={state}
        schema={schema}
        onStateChanged={onStateChanged}
        isSubmitted={isSubmitted}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <div className="d-flex-column">
      <div className='w-100'>
      <CheckboxesComponent
        idRef='printMediaRef'
        labelValue=''
        data={getFeaturedUnitWithPermissions()}
        isRow
        onSelectedCheckboxChanged={(item) => {
          if (onStateChanged) {
            onStateChanged({
              id: item.key,
              value: !state[item.key],
            });
          }
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        translationPathForData={translationPath}
        labelInput='value'
        checked={(selectedItem) => state[selectedItem.key]}
      />
    </div>
    <div className="w-25 mt-2">
    {state.isDealOfTheDay && (
      <Inputs
        idRef="dealExpirationPeriodRef"
        isDisabled={!returnPropsByPermissions(LeadOwnerUnitSalePermissions.EditDealOfTheDay.permissionsId)}
        value={state.dealExpirationPeriod}
        onInputChanged={(e)=>{
          if(e.target.value < 0)
            return
          const dealExpirationPeriodValue=e.target.value
          onStateChanged({
            id: 'dealExpirationPeriod',
            value: dealExpirationPeriodValue,
          });
        }}
        type={"number"}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        endAdornment={
          <span className="title-text ">{t(`${translationPath}days`)}</span>
        }
      />
    )}
  </div>
      </div>


      <PermissionsComponent
        permissionsList={Object.values(LeadOwnerUnitSalePermissions)}
        permissionsId={
          LeadOwnerUnitSalePermissions.EditPublishedToWebsiteMarketing.permissionsId
        }
      >

        {((activeItem && activeItem.operation_type && activeItem.operation_type.lookupItemId === StaticLookupsIds.Sales) || (activeItem && activeItem.operation_type && activeItem.operation_type.lookupItemId === StaticLookupsIds.SaleAndRent)) && (
          <>
            <div className='Publish-wrapper'>
              <div className='Publish-title'>
                <span>{t(`${translationPath}Publish-as`)}</span>
              </div>
              <CheckboxesComponent
                idRef='isPublishUnitSaleRef'
                labelValue=''
                data={[
                  {
                    key: 'isPublishUnitSale',
                    value: 'sale-listing-Unit/psi-website',
                  },
                ]}
                isRow
                onSelectedCheckboxChanged={async(item)=>{
                  if (!state.descriptionEn || !state.titleEn) {
                    setIsSubmitted(true);
                    return;
                  } 
                  const needsApproval=  await isNeedApprovalMessageInMarketing()
                  if(needsApproval){
                    if(isNew){
                      saveHandler()
                    } 
                    setIsDialogOpen(true)
                  }else{
                    if (onStateChanged) {
                          onStateChanged({
                            id: item.key,
                            value: !state[item.key],
                          });
                        }
                  }
                }}  
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
                labelInput='value'
                checked={(selectedItem) => state[selectedItem.key]}
                isDisabled={isPendingRequest?.hasPendingRequest === true || activeItem?.unitStatusDetails?.name.toLowerCase() !== "available" || approvalResponse?.hasPendingRequest === true}
              />
              {/* <CheckboxesComponent
                idRef='isPublishedForDubizzleSaleRef'
                labelValue=''
                data={[
                  {
                    key: 'isPublishedForDubizzleSale',
                    value: 'sale-listing-Unit/dubizzle',
                  },
                ]}
                isRow
                onSelectedCheckboxChanged={(item) => {
                  if (onStateChanged) {
                    onStateChanged({
                      id: item.key,
                      value: !state[item.key],
                    });
                  }
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
                labelInput='value'
                checked={(selectedItem) => state[selectedItem.key]}
              /> */}

              {/* {   isSaleAndRent && (
           <CheckboxesComponent
            idRef='isPublishUnitLeaseRef'
            labelValue=''
            data={[
            {
              key: 'isPublishUnitLease',
              value: 'lease-listing-Unit/psi-website',
            },
            ]}
           isRow
           onSelectedCheckboxChanged={(item) => {
           if (onStateChanged) {
             onStateChanged({
               id: item.key,
               value: !state[item.key],
             });
           }
         }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          translationPathForData={translationPath}
          labelInput='value'
          checked={(selectedItem) => state[selectedItem.key]}
       />
       )} */}

              {/* <CheckboxesComponent
                idRef='bayutRef'
                labelValue=''
                data={[
                  {
                    key: 'isPublishedForBayoutSale',
                    value: 'sale-listing-Unit/bayut',
                  },
                ]}
                isRow
                onSelectedCheckboxChanged={(item) => {
                  if (onStateChanged) {
                    onStateChanged({
                      id: item.key,
                      value: !state[item.key],
                    });
                  }
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
                labelInput='value'
                checked={(selectedItem) => state[selectedItem.key]}
              /> */}
              {/* <CheckboxesComponent
                idRef='PropertyFinderRef'
                labelValue=''
                data={[
                  {
                    key: 'isPublishedForPropertyFinderSale',
                    value: 'sale-listing-Unit/PropertyFinder',
                  },
                ]}
                isRow
                onSelectedCheckboxChanged={(item) => {
                  if (onStateChanged) {
                    onStateChanged({
                      id: item.key,
                      value: !state[item.key],
                    });
                  }
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
                labelInput='value'
                checked={(selectedItem) => state[selectedItem.key]}
              /> */}
              {/*

               <CheckboxesComponent
                idRef='Prian.ruRef'
                labelValue=''
                data={[
                  {
                    key: 'isPublishedForPrianSale',
                    value: 'sale-listing-Unit/Prianru',
                  },
                ]}
                isRow
                onSelectedCheckboxChanged={(item) => {
                  if (onStateChanged) {
                    onStateChanged({
                      id: item.key,
                      value: !state[item.key],
                    });
                  }
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
                labelInput='value'
                checked={(selectedItem) => state[selectedItem.key]}
              />

 */}
              {/* <CheckboxesComponent
                idRef='Houza.comRef'
                labelValue=''
                data={[
                  {
                    key: 'isPublishedForHouzaSale',
                    value: 'sale-listing-Unit/Houzacom',
                  },
                ]}
                isRow
                onSelectedCheckboxChanged={(item) => {
                  if (onStateChanged) {
                    onStateChanged({
                      id: item.key,
                      value: !state[item.key],
                    });
                  }
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
                labelInput='value'
                checked={(selectedItem) => state[selectedItem.key]}
              /> */}
              <p className='approval-message'>
                {approvalResponse?.hasPendingRequest === true &&
                  approvalResponse?.isPublishRequest === true &&
                  'Pending approval publish'}
                {approvalResponse?.hasPendingRequest === true &&
                  approvalResponse?.isPublishRequest === false &&
                  'Pending approval remove publish'}

                {isPendingRequest?.hasPendingRequest === true &&
                  isPendingRequest?.isPublishRequest === true &&
                  'Pending approval publish'}
                {isPendingRequest?.hasPendingRequest === true &&
                  isPendingRequest?.isPublishRequest === false &&
                  'Pending approval remove publish'}
              </p>
            </div>
          </>

        )}
      </PermissionsComponent>
    </div>
  );
};

Descriptions.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  onStateChanged: PropTypes.func.isRequired,
  isMarketAsADifferentAgent: PropTypes.bool.isRequired,
  onIsMarketAsADifferentAgent: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  activeItem: PropTypes.instanceOf(Object).isRequired
};
