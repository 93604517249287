import { DeveloperEmployees ,  BranchesLocation } from '../PeopleAndLocations' ; 
export const PeopleAndLocationsVerticalTabsData = [
 {
   label: 'developer-employees',
   component: DeveloperEmployees ,
 },
 {
   label: 'branches-location',
   component: BranchesLocation ,

 },
 
] ;



