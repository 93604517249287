
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonBase } from '@material-ui/core';
import {
  TabsComponent,
  RadiosGroupComponent,
  Spinner
} from '../../../../Components';
import { GetParams } from '../../../../Helper';
import {  GetDeveloperContactByIdAPI  } from '../../../../Services' ; 
import { DeveloperVerticalTabsData  }  from './Sections/DeveloperVerticalTabsData' ; 

const parentTranslationPath = 'Developers';
const translationPath = '';

export const DevelopersProfileManagementView = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [viewType, setViewType] = useState(1);
  const [developerData , setDeveloperData] = useState(null)
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const [activeContactData , setActiveContactData ] = useState() ;

  

  const [filterBy, setFilterBy] = useState({
    id: null,
    formType: null,
  });

  const [formType, setformType] = useState(+GetParams('formType'));

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };

  const GetDeveloperContactById = useCallback(async (id) => 
    {
      setIsLoading(true);
      const res = await GetDeveloperContactByIdAPI(id || filterBy?.id);
      if (!(res && res.status && res.status !== 200)) 
      {
        if(res && res.developerContactJson )
        {
          const developerContactJson =  res.developerContactJson ; 
          const activeDeveloper = {
            developer :{
              id : res.developerContactId,
              ...developerContactJson,
            }
          }
        setActiveContactData(activeDeveloper);
        setDeveloperData(developerContactJson) ; 
       }
        
      }
      else {
        setDeveloperData(null);
        setActiveContactData(null) ; 

      }
      setIsLoading(false);
    
    }, []);


    const reloadData = () => 
      {
        GetDeveloperContactById(filterBy.id) ;
    
      };

  useEffect(() => {
    setFilterBy({
      formType: (GetParams('formType') && +GetParams('formType')) || null,
      id: (GetParams('id') && +GetParams('id')) || null,
    });
  }, []);

  useEffect(() => {
    setFilterBy({
      formType: (GetParams('formType') && +GetParams('formType')) || null,
      id: (GetParams('id') && +GetParams('id')) || null,
    });
  }, []);

  


  useEffect(() => {
    const developerId = +GetParams('id');
    if (developerId && activeTab === 0 )
        GetDeveloperContactById(developerId);
    
    
  }, [activeTab]);
  return (
    <div className='developer-profile-wrapper view-wrapper px-2'>
      <Spinner isActive={isLoading} isAbsolute />
      {/* { 
       activeTab === 0  && (
        <div className='d-flex-v-center'>
        <RadiosGroupComponent
          idRef='viewDataRef'
          data={[
            {
              key: 1,
              value: 'all-data',
            },
            {
              key: 2,
              value: 'missing-data',
            },
          ]}
          value={viewType}
          labelValue='view'
          labelInput='value'
          valueInput='key'
          themeClass='theme-line'
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          onSelectedRadioChanged={onViewTypeChangedHandler}
        />
      </div>
       )
      } */}
      <TabsComponent
        data ={DeveloperVerticalTabsData}
        labelInput='label'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-solid'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
          id: filterBy.id,
          viewType,
          parentTranslationPath,
          translationPath,
          setActiveTab, 
          activeTab,
          typeOf:'1' , 
          taskRelatedTo : 'Contact', 
          formType : 'contact ' , 
          reloadData:reloadData , 
          developerData , 
        }}
      />
    </div>
  );
};